<template>
  <div>
    <div class="flex justify-between mb-3 items-center flex-wrap">
<!--      <a-input placeholder="Buscar..." v-model="query.search"/>-->
<!--      <a-button @click="$router.back()" class="mr-2">-->
<!--        Regresar-->
<!--      </a-button>-->
      <div></div>
      <a-button :to="{ name: 'create_preinscription' }" tag="a" v-if="!user.isStudent && !user.isLowAdmin" class="w-full md:w-auto">
        Crear reinscripción
      </a-button>
    </div>
    <div>
      <div class="flex items-center justify-between">
        <h3 class="text-2xl my-4">Alumnos retirados</h3>
        <div>
          <a-select
              :loading="loadings.periods"
              class="w-48"
              :source="mappedPeriods"
              v-model="query.period" />
        </div>
      </div>
      <a-table
        :columns="columns"
        :source="pagination.data"
        :pagination="pagination"
        :loading="loadings.index">

<!--        <a-table-->
<!--            :columns="columns"-->
<!--            :source="dummy"-->
<!--            :loading="loadings.index"-->
<!--            :pagination="pagination"-->
<!--            @paginate="search({ page: $event })"-->
<!--            toolbar-column-filter-->
<!--            @toolbar(refresh)="search({ page: 1 })"-->
<!--            @toolbar(search)="search({ search: $event })">-->
        <template #td(name)="{ item }">
          {{ item.last_name + ', ' + item.first_name}}
        </template>
        <template #td(actions)="{ item }">
          <a-dropdown>
            <template #menu>
              <a-dropdown-item @click="handleShowModal(item)">
                Ver retiro
              </a-dropdown-item>
              <a-dropdown-item @click="handleShowStudent(item)">
                Ver estudiante
              </a-dropdown-item>
              <a-dropdown-item @click="$router.push({ name: 'editReinscription', params: { id: item.id } })">
                  Reinscribir estudiante
              </a-dropdown-item>
            </template>
          </a-dropdown>
        </template>
      </a-table>
    </div>
    <a-modal
        v-if="selectedStudent"
        :show="modals.show"
        @cancel="modals.show = false"
        @confirm="modals.show = false"
        :confirm-text="'Imprimir'"
    >
      <p class="text-lg font-bold" >{{ selectedStudent.last_name + ', ' + selectedStudent.first_name }}</p>
      <div class="mt-2">
        <p>Especialidad: <span class="font-bold" :style="`color:${selectedStudent.latest_inscription.specialization.color}`">{{ selectedStudent.latest_inscription.specialization.title + ' - ' + selectedStudent.latest_inscription.semester.number }}</span></p>
        <p>Fecha de retiro: {{ selectedStudent.latest_inscription | date }}</p>
        <p>Motivo: {{ selectedStudent.latest_inscription.withdrawal ? selectedStudent.latest_inscription.withdrawal.motive : '--' }}</p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: "index",
  data () {
    return {
      query: {
        search: '',
        period: null,
        limit: 25,
      },
      loadings: {
        index: false
      },
      modals: {
        show: false,
        withdraw: false
      },
      selectedStudent: null,
      pagination: {},
    }
  },
  computed: {
    ...mapState({
      user: state => state.session.user,
      periods: state => state.periods.all
    }),
    columns() {
      return [
        { title: 'Cédula', key: 'id_document', display: true },
        { title: 'Nombre', slot: 'name', display: true },
        { title: 'Especialidad', key: 'latest_inscription.specialization.title', display: true },
        { title: 'Semestre', key: 'latest_inscription.semester.number', display: true },
        { title: 'Acciones', key: 'id', slot: 'actions', alignment: 'right', columnFilterable: false, display: true },
      ].filter($0 => $0.display)
    },
    mappedPeriods () {
      return this.periods.map($0 => ({ label: `${$0.year}-${$0.number}`, value: $0.id }))
    },
    enabledPeriod() {
      return this.periods.find($0 => $0.enabled) ?? { id: null }
    },
  },
  methods: {
    ...mapActions({
      fetchPeriod: 'periods/index'
    }),
     fetch () {
      this.loadings.index = true
      this.$repository.students
        .listWithdraws(this.query)
        .then((data) => {
          this.pagination = data.data
        })
        .finally(() => this.loadings.index = false)
    },
    handleShowModal (item) {
      this.selectedStudent = item
      this.modals.show = true
    },
    handleShowStudent (item) {
      // console.log(item)
      this.$router.push({ name: 'student', params: { student: item.id}  })
    }
  },
  watch: {
    'query.period'(v, o){
      if (o !== null) {
        this.fetch()
      }
    }
  },
  beforeMount() {
    this.fetchPeriod({ limit: 0 })
      .then(() => {
        this.query.period = this.enabledPeriod.id
        this.fetch()
      })
  }
}
</script>

<style scoped>

</style>